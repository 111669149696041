import React from 'react'

const ComingSoon = () => {
  return (
    <div className='Home_body'>
      <div className='ComingSoon' id="writing">█ █ █ <span style={{ color: 'black' }}>█ █ █ █ █ █ █ █ █ █ </span>31%
        <br />&gt; Site Under Construction&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <br />&gt; Coming Soon  <span id="imleç">█</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </div>
    </div>
  )
}

export default ComingSoon